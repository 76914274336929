@keyframes spin {
  from {
    transform: rotate(180deg); }
  to {
    transform: rotate(360deg); } }

@keyframes linearwipe {
  from {
    width: 0; } }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.flex-start {
  display: flex;
  justify-content: flex-start; }

.header-main {
  display: none; }

.display-0 {
  font-size: 12rem; }

.symbol {
  animation: spin 2s 1 linear; }

.card-deck {
  padding-bottom: 2.5rem;
  padding-top: 0rem; }

.footer {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  .footer img {
    vertical-align: top; }

.hero {
  padding-bottom: 1rem; }
  .hero h5 {
    font-weight: 300;
    line-height: 1.7rem;
    letter-spacing: 0.1rem;
    text-align: left;
    padding-top: 1rem; }
  .hero .card-body {
    display: flex;
    align-items: center;
    justify-content: center; }

@media (max-width: 767.98px) {
  .card-deck {
    padding-bottom: 1rem;
    padding-top: 1rem; }
    .card-deck .card {
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem; }
  .symbol {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -2rem;
    margin-bottom: -2rem; }
  .footer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex; }
    .footer .card {
      margin-bottom: 0;
      flex: 1; } }

.bg-intelligence {
  background-color: #ed6a56; }

.bg-the-blue {
  background-color: #3a476c; }

.bg-endangered-project {
  background-color: #0e0e0e; }

.bg-endangered-project-2 {
  background-color: #43738c; }

.bg-the-blog {
  background-color: #7d6c62; }

.bg-film {
  background-color: #07031A; }

.social-icon {
  margin-right: 0.5em; }

.hide-overflow {
  overflow: hidden; }

.none {
  display: none; }
