.FlexDrawerContainer {
  display: flex;
  flex-flow: row wrap; }

.FlexDrawer {
  position: relative;
  width: 100%; }

.drawer-heading {
  word-spacing: 0.5rem; }

.is-flex-container {
  display: flex; }
